import c from '@/utils/config'

export default {
	URI_HOME: 'home',
		/*	获取工作台数据
			GET
		*/
	URI_TAGS: 'tags',
		/*	
			GET		获取标签数据
		*/
	URI_LOGIN: 'login',
	URI_USERS: 'users',
	URI_CLUES: 'clues',
	URI_CARTS: 'carts',
	URI_BILLS: 'bills',
	URI_ROLES: 'roles',
	URI_SHOPS: 'shops',
	URI_GOODS: 'goods',
	URI_VIDEOS: 'videos',
	URI_ORDERS: 'orders',
	URI_BRANDS: 'brands',
	URI_TOPICS: 'topics',
	URI_SCORES: 'scores',
	URI_CONFIG: 'config',
	URI_PASSWD: 'passwd',
	URI_HOLIDAY: 'holiday',
	URI_EXCEPTION: 'exception',
	URI_COUPONS: 'coupons',
	URI_MARKETS: 'markets',
	URI_MEMBERS: 'members',
	URI_H5PAGES: 'h5pages',
	URI_WALLETS: 'wallets',
	URI_CLIENTS: 'clients',
	URI_CHECKIN: 'checkin',
	URI_COURSES: 'courses',
	URI_REGIONS: 'regions',
	URI_WAITERS: 'waiters',
	URI_ARTICLES: 'articles',
	URI_SPECIALS: 'specials',
	URI_SEARCHES: 'searches',
	URI_INVOICES: 'invoices',
	URI_SALARIES: 'salaries',
	URI_CONTACTS: 'contacts',
	URI_MATERIALS: 'materials',
	URI_ADDRESSES: 'addresses',
	URI_CONTRACTS: 'contracts',
	URI_POSITIONS: 'positions',
	URI_EMPLOYEES: 'employees',
	URI_APPROVALS: 'approvals',
	URI_EXPRESSES: 'expresses',
	URI_COMPANIES: 'companies',
	URI_SUPPLIERS: 'suppliers',
	URI_DELIVERIES: 'deliveries',
	URI_CATEGORIES: 'categories',
	URI_LUCKYDRAWS: 'luckydraws',
	URI_WAREHOUSES: 'warehouses',
	URI_INSURANCES: 'insurances',
	URI_STATISTICS: 'statistics',
	URI_PERMISSIONS: 'permissions',
	URI_DRAWRECORDS: 'drawrecords',
	URI_PICMATERIALS: 'picmaterials',
	URI_PERFORMANCES: 'performances',
	URI_ADMIN_LOGS: 'admin/logs',
	URI_GOODS_CONTROLS: 'goods/controls',
	URI_INSURANCES_TEMPS: 'insurances/temps',
	URI_DELIVERIES_SERVICES: 'deliveries/services',
	URI_POSITIONS_CANDIDATES: 'positions/candidates',
	URI_POSITIONS_RECRUITMENTS: 'positions/recruitments',
	URI_GOODS_ATTACHMENTS: 'goods/attachments',
	URI_EMPLOYEES_EXPENSES: 'employees/expenses',
	URI_EMPLOYEES_PAYMENTS: 'employees/payments',
	URI_EMPLOYEES_DEPARTURES: 'employees/departures',
	URI_EMPLOYEES_ENROLLMENTS: 'employees/enrollments',
	URI_MATERIALS_INVENTORIES: 'materials/inventories',
	URI_MATERIALS_DELIVERIES: 'materials/deliveries',
	URI_WAREHOUSES_MATERIALS: 'warehouses/materials',
	URI_MATERIALS_SUPPLIERS: 'materials/suppliers',
	URI_MATERIALS_TRANSFERS: 'materials/transfers',
	URI_MATERIALS_PURCHASES: 'materials/purchases',
	URI_MATERIALS_SHEETS: 'materials/sheets',
	URI_MATERIALS_PURCHASES_SHEETS: 'materials/purchases/sheets',
	URI_MATERIALS_TRANSFERS_SHEETS: 'materials/transfers/sheets',
	URI_MATERIALS_DELIVERIES_SHEETS: 'materials/deliveries/sheets',
	URI_MATERIALS_INVENTORIES_SHEETS: 'materials/inventories/sheets',
	URI_SALARIES_DETAILS: 'salaries/details',
	URI_SALARIES_FIELDS: 'salaries/fields',
	URI_SALARIES_CONFIGS: 'salaries/configs',
	URI_SOCIAL_ACCOUNTS: 'social/accounts',
	URI_CLIENTS_FEES: 'clients/fees',
	URI_CLIENTS_FOLLOWS: 'clients/follows',
	URI_CLIENTS_TICKETS: 'clients/tickets',
	URI_CLUES_FOLLOWS: 'clues/follows',
	URI_USER_ROUTES: 'user/routes',
	URI_GOODS_TYPES: 'goods/types',
	URI_BAIDU_APPS: 'baidu/apps',
	URI_WOCHAT_APPS: 'wochat/apps',
	URI_BAIDU_USERS: 'baidu/users',
	URI_WOCHAT_USERS: 'wochat/users',
	URI_ORDERS_GOODS: 'orders/goods',
	URI_EXPRESSES_FEES: 'expresses/fees',
	URI_SHORT_MESSAGES: 'short/messages',
	URI_ORDERS_OPERATES: 'orders/operates',
	URI_GOODS_DISCOUNTS: 'goods/discounts',
	URI_COUPONS_RECORDS: 'coupons/records',
	URI_TYPE_ATTRIBUTES: 'type/attributes',
	URI_GOODS_ATTRIBUTES: 'goods/attributes',
	URI_COURSES_CHAPTERS: 'courses/chapters',
	URI_WOCHAT_DEPARTMENTS: 'wochat/departments',
	URI_PERFORMANCES_RATES: 'performances/rates',
	URI_COMPANIES_SHAREHOLDERS: 'companies/shareholders',
	URI_PERFORMANCES_OBJECTIVES: 'performances/objectives',
	URI_WECHAT_APPS: 'wechat/apps',
	URI_WECHAT_USERS: 'wechat/users',
	URI_WECHAT_CONFIG: 'wechat/config',
	URI_WECHAT_DRAFTS: 'wechat/drafts',
	URI_WECHAT_ARTICLES: 'wechat/articles',
	URI_WECHAT_KEYWORDS: 'wechat/keywords',
	URI_WECHAT_MATERIALS: 'wechat/materials',
	URI_WECHAT_MERCHANTS: 'wechat/merchants',
	URI_WECHAT_FREEPUBLISH: 'wechat/freepublish',
	URI_WECHAT_COMPONENT: 'wechat/component',
	URI_ALIPAY_APPS: 'alipay/apps',
	URI_ALIPAY_USERS: 'alipay/users',
	URI_TOUTIAO_APPS: 'toutiao/apps',
	URI_TOUTIAO_USERS: 'toutiao/users',
	URI_DINGTALK_USERS: 'dingtalk/users',
	URL_CONFIGURATIONS: 'configurations',
	URI_UPLOADS: c.APIHOST+'uploads',
		/*	因文件上传不使用 axios，需要拼接完整链接使用 element 组件
			POST:
				appid	选填。微信appid直接上传微信平台，不保存服务器本地。在微信支付优惠券素材使用
				type	必填。填appid时非必填，根据此参数限制上传文件类型大小以及保存服务器路径
		*/
}